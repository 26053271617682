<template>
  <div>
  <section class="hero">
    <div class="container mx-auto">
      <h1 class="sm:w-1/2 mb-1">{{ $t("hero.heading") }}</h1>
      <p class="text-lg mb-5">{{ $t("hero.text") }}</p>
      <div class="flex gap-3 justify-start">
        <router-link :to="{name: 'Signup'}" class="btn bg-rc-primary-default hover:bg-rc-primary-default-alt text-rc-primary-light btn-lg me-md-1"  v-if="!authStore.isAuthenticated" >{{ $t("hero.signUp") }}</router-link>
        <router-link :to="{name: 'Readers'}" class="btn bg-rc-primary-default hover:bg-rc-primary-default-alt text-rc-primary-light btn-lg me-md-1"  v-else>{{ $t("hero.readers") }}</router-link>
      </div>
    </div>
  </section>
  </div>
</template>
<script setup>
import { useAuthStore } from '@core/stores';
const authStore = useAuthStore();

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

