<template>
  <footer>
    <div class="container text-center md:text-start">
      <div class="row lg:grid lg:grid-cols-2 lg:gap-12 justify-between mb-8">
        <div class="mb-7 md:mb-5">
          <router-link :to="{name:'Home'}" class="logo navbar-brand mb-5 justify-center md:justify-start" href="#">
            <!-- logo  -->
            <img src="../assets/img/logo.svg">
            <h3>{{company.brand}}</h3>
          </router-link>
          <FooterCompany :company="company"/>
        </div>
        
        <div class="mb-7 md:mb-0 md:grid md:grid-cols-2 md:gap-12">
          <div class="mb-7 md:mb-0 md:w-50">
            <h3>{{ $t("footer.usefulLink") }}</h3>
            <ul class="mt-3 md:mt-5">
              <li><router-link to="/terms-and-conditions">{{ $t("footer.terms") }}</router-link></li>
              <li><router-link to="/privacy-policy">{{ $t("footer.privacy") }}</router-link></li>
              <li><router-link to="/payment-policy">{{ $t("footer.payment") }}</router-link></li>
              <!-- <li><router-link to="/refund-policy">{{ $t("footer.refund") }}</router-link></li> -->
              <li><router-link to="/faq">{{ $t("footer.faq") }}</router-link></li>
            </ul>
          </div>
          <div class="mb-7 md:mb-0 md:w-50">
            <h3>{{ $t("footer.accountInformation") }}</h3>
            <ul class="mt-3 md:mt-5"> 
              <li v-if="!authStore.isAuthenticated"><router-link :to="{name:'Signup'}">{{ $t("footer.getStarted") }}</router-link></li>
              <li v-else><router-link :to="{name:'MyAccount'}">{{ $t("footer.myAccount") }}</router-link></li>
              <li v-if="!authStore.isAuthenticated"><router-link :to="{name:'Login'}">{{ $t("footer.login") }}</router-link></li>
              <li v-else><router-link :to="{name:'Packages'}">{{ $t("footer.getCredits") }}</router-link></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="md:flex justify-center text-center items-center pt-8 border-t border-rc-primary-dark-alt" id="copyright">
        <FooterCopyright :company="company"/>
        <FooterCards/>
      </div>
    </div>
    <!-- Modal for no coach -->
    <Modal :open="open" id="universal">
      <template #modalTitle>
        <button @click="closeClear"><XMarkIcon /></button>
      </template>
      <template #modalContent>
        <div class="text-center" id="center-card">
          <p>Limited Time Offer <br/><strong>Unlimited chat</strong> <br/>for just $1</p>
          <button @click="closeRedirect" class="btn btn-accent !text-dark-600 btn-lg me-md-1" href="#">Buy Credits</button>
        </div>
      </template>
      <template #modalBtns>
      </template>
    </Modal>
  </footer>
  
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script setup>

  import { ref, onMounted, onUnmounted } from "vue"
  import company from '@/data/company.json'
  import FooterCards from '@core/components/FooterCards.vue'
  import FooterCopyright from '@core/components/FooterCopyright.vue'
  import FooterCompany from '@core/components/FooterCompany.vue'
  import Modal from '@core/components/utilities/Modal.vue'
  import { XMarkIcon } from '@heroicons/vue/24/outline'

  import { useAuthStore } from '@core/stores';
  import { useStorage } from "vue3-storage";
  import router from '@/router';
  
  const toggleMenu = () => {
    document.getElementById("navbarCollapse").classList.toggle("hidden");
  }
  const authStore = useAuthStore();
  const open = ref(false);
  const storage = useStorage();

  onMounted( () => {
    intervalManager(false, 1000); 
  })

  const manageOfferPopup = () => {
    const timeNow = ref( Math.floor(Date.now() / 1000) );
    if( !storage.getStorageSync("offerShown")) {
      if(timeNow.value >= storage.getStorageSync("cLogTime")) {
        open.value = true;
        intervalManager(false)
      }
    }
  }

  let offerPopupTimer = null;
  function intervalManager(flag, time) {
    if(flag)
      offerPopupTimer =  setInterval(manageOfferPopup, time);
    else
      clearInterval(offerPopupTimer);
  }

  // Action of Customer click Buy Credits Button
  const closeRedirect = () => {
    closeClear();
    router.push({name: 'Credits'});
  }

  // Action of Customer click the Close Modal Button
  const closeClear = () => {
    open.value = false;

    // Reset expiry to 60 seconds and reopen the timer for 2nd time only
    if(!storage.getStorageSync("offeredTwice")){
      storage.setStorageSync("offeredTwice", true);
      storage.setStorageSync("cLogTime", Math.floor( (Date.now() + (45 * 1000)) / 1000) ); 
      intervalManager(false, 1000);  
    }
    // Permanently close timer
    else {
      storage.setStorageSync("offerShown", true); 
      storage.removeStorageSync("cLogTime");
    }

    
  }
</script>

