<template>
  <div class="home">
    <Hero/>
    <div class="bg-gray-50">
      <div class="container mx-auto pt-10 pb-0 text-center">
        <h1>Meet our Readers</h1>
      </div>
    </div>
    <section class="bg-gray-50 py-0">
      <div class="container w-full sm:w-[80%]">
        <CoachList/>
      </div>
    </section>
    <Explore/>
    <Features/>
    <About/>
    <CallToAction/>
    <!-- <HowItWorks/> -->
    <!-- <Articles/> -->
    <!-- <Testimonials/> -->
  </div>
</template>

<script setup>
import { defineAsyncComponent, onMounted, ref } from 'vue';
import { useStorage } from "vue3-storage";
import { useRoute } from 'vue-router';
import Hero from '@/components/Hero.vue';
import { trackKlaviyo } from '@core/composables/utilities';

// lazy loaded components after Hero
const Explore = defineAsyncComponent(()=> import('@/components/Explore.vue'))
const CoachList = defineAsyncComponent(()=> import('@core/components/CoachList.vue'))
const About = defineAsyncComponent(()=> import('@/components/About.vue'))
const Features = defineAsyncComponent(()=> import('@/components/Features.vue'))
const CallToAction = defineAsyncComponent(()=> import('@/components/CallToAction.vue'))

const storage = useStorage();
const route = useRoute();
const customerInfo = ref(storage.getStorageSync('customerInfo'));

onMounted(async () => {
  // Init Klaviyo Metric - 'Viewed Product'
  await trackKlaviyo('Viewed Product', customerInfo.value, route.path);
})

</script>
