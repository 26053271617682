<template>
  <header id="navbar" ref="navbar" class="fixed-top">
    <nav>
      <div class="container">
        <router-link :to="{ name: 'Home' }" class="logo navbar-brand">
          <!-- logo  -->
          <slot name="logo">
            <!-- Default slot content  -->
            <h3>domain</h3>
          </slot>
        </router-link>

        <button data-collapse-toggle="navbar-default" @click="toggleMenu" type="button" aria-controls="navbar-default"
          aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"></path>
          </svg>
        </button>

        <div class="hidden" id="navbarCollapse">
          <div class="nav-btn-main">
            <ul ref="nav">
              <li class="nav-item">
                <router-link :to="{ name: 'Home' }" class="nav-link" aria-current="page" @click="toggleMenu">{{
                  $t("header.home") }}</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Readers' }" class="nav-link" @click="toggleMenu">{{ $t("header.readers")
                  }}</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'About' }" class="nav-link" @click="toggleMenu">{{ $t("header.about")
                  }}</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'FAQ' }" class="nav-link" @click="toggleMenu">{{ $t("header.faq")
                  }}</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Contact' }" class="nav-link" @click="toggleMenu">{{ $t("header.contact")
                  }}</router-link>
              </li>
            </ul>
          </div>

          <div class="nav-btn-mobile flex sm:hidden">
            <ul class="w-full flex items-center gap-2 sm:gap-4 ">
              <!-- Reactive Nav Items-->
              <template v-if="authStore.isAuthenticated">
                <li class="nav-item">
                  <router-link :to="{ name: 'MyAccount' }" class="btn btn-sm border border-rc-primary-default text-rc-primary-default hover:bg-rc-primary-default hover:text-rc-primary-light" @click="toggleMenu">{{ $t("header.myAccount")
                    }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'Packages' }" class="btn btn-sm border border-rc-primary-default bg-rc-primary-default hover:bg-rc-primary-dark-alt text-rc-primary-light" @click="toggleMenu">{{
                    $t("header.getCredits") }}</router-link>
                </li>
              </template>

              <!-- Login & Signup Toggle -->
              <template v-else>
                <li class="nav-item">
                  <router-link :to="{ name: 'Login' }" class="btn btn-sm border border-rc-primary-default text-rc-primary-default hover:bg-rc-primary-default hover:text-rc-primary-light" @click="toggleMenu">{{ $t("header.login")
                    }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'Signup' }" class="btn btn-sm border border-rc-primary-default bg-rc-primary-default text-rc-primary-light" @click="refreshSignUp">{{
                    $t("header.getStarted") }}</router-link>
                </li>
              </template>
            </ul>
          </div>
        </div>

        <div class="nav-btn hidden sm:flex">
          <ul class="flex items-center gap-2 sm:gap-4">
            <!-- Reactive Nav Items-->
            <template v-if="authStore.isAuthenticated">
              <li class="nav-item">
                <router-link :to="{ name: 'MyAccount' }" class="btn btn-sm border border-rc-primary-default text-rc-primary-default hover:bg-rc-primary-default hover:text-rc-primary-light" @click="toggleMenu">{{ $t("header.myAccount")
                  }}</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Packages' }" class="btn btn-sm border border-rc-primary-default bg-rc-primary-default hover:bg-rc-primary-dark-alt text-rc-primary-light" @click="toggleMenu">{{
                  $t("header.getCredits") }}</router-link>
              </li>
            </template>

            <!-- Login & Signup Toggle -->
            <template v-else>
              <li class="nav-item">
                <router-link :to="{ name: 'Login' }" class="btn btn-sm border border-rc-primary-default text-rc-primary-default hover:bg-rc-primary-default hover:text-rc-primary-light" @click="toggleMenu">{{ $t("header.login")
                  }}</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Signup' }" class="btn btn-sm border border-rc-primary-default bg-rc-primary-default text-rc-primary-light" @click="refreshSignUp">{{
                  $t("header.getStarted") }}</router-link>
              </li>
            </template>
          </ul>
        </div>

      </div>
    </nav>

  </header>

</template>

<script setup>
import { onMounted, ref, watchEffect, watch } from "vue"
import { useAuthStore } from '@core/stores';
import { useRoute, useRouter } from 'vue-router'

const navbar = ref();
const props = defineProps(['logo']);
const router = useRouter();

const getLogoUrl = () => {
  return new URL(props.logo, import.meta.url)
}
onMounted(() => { })

// toggle menu for smaller screens
const toggleMenu = () => {
  document.getElementById("navbarCollapse").classList.toggle("hidden");
}

const refreshSignUp = async () => {
  await router.push('/get-started');
  router.go(0);
};

const authStore = useAuthStore();
</script>
